import { Controller } from "@hotwired/stimulus"
import TomSelect      from "tom-select"

// Connects to data-controller="cuisines"
export default class extends Controller {
  connect() {
    var config = {
      plugins: ['remove_button'],
    }

    new TomSelect(this.element, config)
  }
}
