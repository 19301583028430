import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  copyLink(event) {
    event.preventDefault()
    navigator.clipboard.writeText(this.element.dataset.shareUrl).then(() => {
      alert(I18n.copy);
    });
  }
}
