import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "field", "map", "latitude", "longitude" ]

  connect() {
    if (typeof(google) != "undefined") {
      this.initMap()
    }
  }

  initMap() {
    this.map = new google.maps.Map(this.mapTarget, {
      // São Paulo as center
      center: new google.maps.LatLng(this.data.get("latitude") || -23.53, this.data.get("longitude") || -46.65),
      zoom: (this.data.get("latitude") == null ? 4 : 15)
    })

    this.autocomplete = new google.maps.places.Autocomplete(this.fieldTarget, {
      componentRestrictions: { country: ["br", "us"] }})
    this.autocomplete.bindTo("bounds", this.map)
    this.autocomplete.setFields(["address_components", "geometry", "icon", "name"])
    this.autocomplete.addListener("place_changed", this.onPlaceChanged.bind(this))

    this.marker = new google.maps.Marker({
      map: this.map,
      anchorPoint: new google.maps.Point(0, -29)
    })
  }

  onPlaceChanged() {
    let place = this.autocomplete.getPlace()

    if (!place.geometry) {
      window.alert(`No details available for input: ${place.name}`)
      return
    }

    if (place.geometry.viewport) {
      this.map.fitBounds(place.geometry.viewport)
    } else {
      this.map.setCenter(place.geometry.location)
      this.map.setZoom(17)
    }

    this.marker.setPosition(place.geometry.location)
    this.marker.setVisible(true)

    for (var i in place.address_components) {
      var component = place.address_components[i]
      for (var j in component.types) {
        var type_element = document.getElementById(component.types[j])
        if (type_element) {
          if (component.types[j] == "administrative_area_level_1") {
            type_element.value = component.short_name
          } else {
            type_element.value = component.long_name
          }
        }
        // Save address as Brazilian format
        document.getElementById("address1").value = place.address_components[1]["long_name"] + ', ' +
        place.address_components[0]["long_name"]
      }
    }

    this.latitudeTarget.value = place.geometry.location.lat()
    this.longitudeTarget.value = place.geometry.location.lng()
  }

  keydown(event) {
    if (event.key == "Enter") {
      event.preventDefault()
    }
  }
}
