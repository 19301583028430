// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
// import { Turbo } from "@hotwired/turbo-rails" // works too
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "@fortawesome/fontawesome-free/css/all"

import "stylesheets/application.scss"

Rails.start()
ActiveStorage.start()

import "controllers"

import 'alpine-turbo-drive-adapter'
import Alpine from "alpinejs"

window.Alpine = Alpine
// Alpine.start()

const images = require.context('../images', true)

document.addEventListener("DOMContentLoaded", function(event) {
  window.Alpine.start();
});

document.addEventListener("turbo:load", () => {
  const allstars = document.querySelectorAll('.fa-star')
  let hiddenRatingInput = document.querySelector('#rating')

  allstars.forEach(star => {
    star.onclick = () => {
      let starlevel = star.getAttribute('data-num')
      hiddenRatingInput.value = starlevel
      allstars.forEach(el => {
        if (starlevel < el.getAttribute('data-num')) {
          el.classList.remove('fas')
          el.classList.add('far')
        }
        else {
          el.classList.remove('far')
          el.classList.add('fas')
        }
      })
    }
  })
})
